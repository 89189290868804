'use client';

import { useUserSessionHook } from '@/components/messaging/emailTemplates/hooks/useUserSessionHook';
import { usePathname } from 'next/navigation';
import React, { useState, type JSX, type ReactNode } from 'react';
import { AlertProvider } from '../general/alert/AlertContext';
import MainPageContent from '../general/MainPageContent';
import { userSessionContext } from '../iam/UserSessionContext';
import HeaderNavigation from './headerNavigation/HeaderNavigation';
import './navigation.css';
import Sidebar from './sidebar/Sidebar';

export interface IRootLayoutProps {
  children: ReactNode;
  breadcrumb: ReactNode;
}

const NavigationContainer: React.FC<IRootLayoutProps> = ({ children, breadcrumb }): JSX.Element => {
  const path = usePathname();
  if (path === '/sign-in') return <>{children}</>;

  const session = useUserSessionHook();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<Record<number, boolean>>({});
  const closeSidebar = () => {
    setOpenSidebar(false);
    setDropdownOpen({});
  };

  return (
    <div>
      <userSessionContext.Provider value={session}>
        <AlertProvider>
          <div id="root" className="bg-background flex min-h-screen flex-col">
            <HeaderNavigation openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} closeSidebar={closeSidebar} />
            <div className="flex-1 sm:ml-14">
              <div className={`h-full sm:shrink-0 sm:pr-16`}>
                <Sidebar
                  openSidebar={openSidebar}
                  setOpenSidebar={setOpenSidebar}
                  dropdownOpen={dropdownOpen}
                  setDropdownOpen={setDropdownOpen}
                  closeSidebar={closeSidebar}
                />
              </div>
              <div className="relative sm:grow">
                <div className="w-full">
                  <MainPageContent children={children} breadcrumb={breadcrumb} />
                </div>
              </div>
            </div>
          </div>
        </AlertProvider>
      </userSessionContext.Provider>
    </div>
  );
};

export default NavigationContainer;
