'use client';

import { isAuthorized } from '@/app/utils/session';
import { Permission } from '@tiketti/types';
import { useContext, useEffect, useState, type JSX } from 'react';
import Unauthorized from './Unauthorized';
import { userSessionContext } from './UserSessionContext';

const defaultUnauthorized = <Unauthorized />;

const Authorized = (props: {
  children: JSX.Element;
  permissions: Permission[] | [];
  else?: JSX.Element;
}): JSX.Element => {
  const { children, permissions } = props;

  const session = useContext(userSessionContext);
  const [permitted, setPermitted] = useState(false);

  useEffect(() => {
    const ok = props.permissions.some((p) => isAuthorized(session, p));
    setPermitted(ok);
  }, [session, props.permissions]);

  return permitted ? props.children : (props.else ?? defaultUnauthorized);
};

export default Authorized;
