export type ImageType = 'image' | 'icon';

interface CloudflareLoaderParams {
  src: string;
  width: number;
  quality?: number;
  type?: ImageType;
}

const normalizeSrc = (src: string): string => {
  if (src.startsWith('http')) {
    // No need to modify absolute URLs
    return src;
  }

  // Remove leading slash for relative URLs
  return src.startsWith('/') ? src.slice(1) : src;
};

const cloudflareLoader = ({ src, width, quality, type }: CloudflareLoaderParams): string => {
  const normalizedSrc = normalizeSrc(src);

  if (process.env.NODE_ENV === 'development') {
    // Add a leading slash if the URL do NOT start with http
    return src.startsWith('http') ? normalizedSrc : `/${normalizedSrc}`;
  }

  if (src.startsWith('http')) {
    // Handle absolute URLs differently in production
    return `${src}?width=${width}${quality != null ? `&quality=${quality}` : ''}`;
  }

  if (type && type === 'icon') {
    return `/${normalizedSrc}`;
  }

  const params = [`width=${width}`];
  if (quality != null) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(',');
  return `/cdn-cgi/image/${paramsString}/${normalizedSrc}`;
};

export default cloudflareLoader;
