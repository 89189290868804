import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { blocksConfigSlice, eventsSlice, pageConfigSlice } from './slices';

const rootReducer = combineReducers({
  blocksConfig: blocksConfigSlice.reducer as Reducer,
  pageConfig: pageConfigSlice.reducer as Reducer,
  events: eventsSlice.reducer as Reducer,
});

export const makeStore = (): ReturnType<typeof configureStore> => {
  return configureStore({
    reducer: rootReducer,
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type RootStateType = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore['dispatch'];

export class StoreKeeper {
  public static store: any = makeStore();
}
