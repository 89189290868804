import { chooseAllAuthorized, PermissibleValues, Session } from '@/app/utils/session';
import {
  BookOpenIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  ChartBarIcon,
  CubeIcon,
  CurrencyEuroIcon,
  InboxIcon,
  InformationCircleIcon,
  LanguageIcon,
  MapIcon,
} from '@heroicons/react/24/outline';
import { allPermissions } from '@tiketti/permissions';
import { PageType } from '@tiketti/types';
import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { userSessionContext } from '../../iam/UserSessionContext';
import SidebarDesktop from './SidebarDesktop';
import SidebarMobile from './SidebarMobile';

const STAGE = process.env.NEXT_PUBLIC_STAGE ?? 'dev';

export interface ISideBarNavigationNavItem {
  name: string;
  href: string;
  icon?: ReactNode;
  current: boolean;
  target?: string;
}

export interface ISideBarNavigationItem {
  header?: string; // Header is optional, used only if there's more than one navItem
  navItem: ISideBarNavigationNavItem[];
}

export interface ISidebarProps {
  openSidebar: boolean;
  setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  sideBarNavigationItems: ISideBarNavigationItem[];
  dropdownOpen: Record<number, boolean>;
  toggleSidebar: (isOpen: boolean) => void;
  toggleDropdown: (index: number) => void;
  handleRowClick: (index: number, isMultiNavItem: boolean) => void;
  closeSidebar: () => void;
}

const permissionToNavigationMap: Array<
  PermissibleValues<ISideBarNavigationItem> & { excludeOnStage?: string[] | null }
> = [
  {
    permission: allPermissions.notification.SEE,
    values: [
      {
        header: 'Messages',
        navItem: [
          { name: 'System Messaging', href: '/messaging', icon: <InboxIcon aria-hidden="true" />, current: false },
          {
            name: 'Email Templates',
            href: '/messaging/templates',
            current: false,
          },
        ],
      },
      {
        navItem: [
          {
            name: 'Translations',
            href: '/texts',
            icon: <LanguageIcon aria-hidden="true" />,
            current: false,
          },
        ],
      },
    ],
  },
  {
    permission: [allPermissions.organization.SEE, allPermissions.organization.LIST],
    values: [
      {
        navItem: [
          {
            name: 'Organization Management',
            href: '/organization/list',
            icon: <BuildingOffice2Icon aria-hidden="true" />,
            current: false,
          },
        ],
      },
    ],
  },
  {
    permission: allPermissions.priceList.SEE,
    excludeOnStage: ['prod'],
    values: [
      {
        navItem: [
          {
            name: 'Master price list',
            href: '/price/list',
            icon: <CurrencyEuroIcon aria-hidden="true" />,
            current: false,
          },
        ],
      },
    ],
  },
  {
    permission: allPermissions.events.CREATE,
    values: [
      {
        navItem: [
          {
            name: 'Blocks builder',
            href: '/blocks-builder/blocks',
            icon: <CubeIcon aria-hidden="true" />,
            current: false,
          },
        ],
      },
      {
        navItem: [
          {
            name: 'Events',
            href: '/events/list',
            icon: <CalendarDaysIcon aria-hidden="true" />,
            current: false,
          },
        ],
      },
    ],
  },
  {
    permission: allPermissions.venues.LIST,
    values: [
      {
        navItem: [
          {
            name: 'Venues',
            href: '/venue/list',
            icon: <MapIcon aria-hidden="true" />,
            current: false,
          },
        ],
      },
      {
        header: 'Page Management',
        navItem: [
          {
            name: 'All Pages',
            href: '/pageManagement/list',
            icon: <BookOpenIcon aria-hidden="true" />,
            current: false,
          },
          {
            name: 'Landing Pages',
            href: `/pageManagement/list?pageType=${PageType.LANDING}`,
            current: false,
          },
          {
            name: 'Info Pages',
            href: `/pageManagement/list?pageType=${PageType.INFO}`,
            current: false,
          },
          {
            name: 'Articles',
            href: `/pageManagement/list?pageType=${PageType.ARTICLE}`,
            current: false,
          },
          {
            name: 'Artists',
            href: `/pageManagement/list?pageType=${PageType.ARTIST}`,
            current: false,
          },
        ],
      },
    ],
  },
  {
    permission: [
      allPermissions.reports.SEE,
      allPermissions.reports.organization.SEE,
      allPermissions.reports.unlimited.SEE,
    ],
    values: [
      {
        header: 'Reports',
        navItem: [
          {
            name: 'powerbi-sales-report',
            href: `/reports`,
            icon: <ChartBarIcon aria-hidden="true" />,
            current: false,
          },
          {
            name: 'report-help',
            href: 'https://assets.tiketti.fi/production/reports-guide.pdf',
            icon: <InformationCircleIcon aria-hidden="true" />,
            current: false,
            target: '_blank',
          },
        ],
      },
    ],
  },
];

const getPermittedNavigationItems = (session: Session) =>
  chooseAllAuthorized(
    session,
    permissionToNavigationMap.filter((n) => !n.excludeOnStage || !n.excludeOnStage?.includes(STAGE)),
  );

export default function Sidebar(props: {
  openSidebar: boolean;
  setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  closeSidebar: () => void;
  dropdownOpen: Record<number, boolean>;
  setDropdownOpen: Dispatch<SetStateAction<Record<number, boolean>>>;
}) {
  const session = useContext(userSessionContext);

  const [sideBarNavigationItems, setSideBarNavigationItems] = useState<ISideBarNavigationItem[]>([]);

  useEffect(() => {
    if (session) {
      setSideBarNavigationItems(getPermittedNavigationItems(session));
    } else {
      setSideBarNavigationItems([]);
    }
  }, [session, setSideBarNavigationItems]);

  const toggleSidebar = (isOpen: boolean) => {
    if (!isOpen) {
      props.setDropdownOpen({});
    }
    props.setOpenSidebar(isOpen);
  };

  const toggleDropdown = (index: number) => {
    props.setDropdownOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleRowClick = (index: number, isMultiNavItem: boolean) => {
    if (props.openSidebar) {
      if (isMultiNavItem) toggleDropdown(index);
    } else {
      toggleSidebar(true);
      if (isMultiNavItem) toggleDropdown(index);
    }
  };

  return (
    <>
      <SidebarDesktop
        {...props}
        handleRowClick={handleRowClick}
        toggleDropdown={toggleDropdown}
        toggleSidebar={toggleSidebar}
        sideBarNavigationItems={sideBarNavigationItems}
      />
      <SidebarMobile
        {...props}
        handleRowClick={handleRowClick}
        toggleDropdown={toggleDropdown}
        toggleSidebar={toggleSidebar}
        sideBarNavigationItems={sideBarNavigationItems}
      />
    </>
  );
}
