import Image from 'next/image';
import cloudflareLoader from '~/imageLoader';

import type { JSX } from "react";

const HeaderNavigationLogo = (): JSX.Element => {
  return (
    <>
      <Image
        loader={cloudflareLoader}
        src="https://assets.tiketti.fi/production/img/2021/tiketti_logo.svg"
        alt="Tiketti Logo"
        width="0"
        height="0"
        className="w-[128px] h-[128px]"
        priority
      />
    </>
  );
};

export default HeaderNavigationLogo;
