'use client';

import { AbstractIntlMessages, IntlError, NextIntlClientProvider } from 'next-intl';
import { ReactNode } from 'react';
interface IntlProviderProps {
  locale: string;
  messages: AbstractIntlMessages;
  children: ReactNode;
}

export default function IntlProvider({ locale, messages, children }: IntlProviderProps) {


  return (
    <NextIntlClientProvider
      onError={(error: IntlError) => {

        // const windowLocationHost = window?.location?.host || ''

        // // console.log('Intl Error:', error.originalMessage);
        // // TODO. log error for example to console so missing translations can be seen.
        // // Commenting this out now, because there is so much noise in the console because of missing translations.
        // // UPDATE: useful for dev env.. lets handle localhost as a skip log case
        // /*const { originalMessage } = error;
        // console.log(`Intl Error: ${originalMessage}`);*/
        if (typeof window !== "undefined") {
          const windowLocationHost = window?.location?.host || ''
          if (!windowLocationHost.includes("localhost")) {
            const { originalMessage } = error;
            console.log(`Intl Error: ${originalMessage}`);
          }
        }
      }}
      locale={locale}
      messages={messages}
    >
      {children}
    </NextIntlClientProvider>
  );
}
