import { PermissionConditionCheckerParameters, PermissionConditionCheckResult } from '@tiketti/types';

export function organizationIdMatchesSession({
  session,
  context,
}: PermissionConditionCheckerParameters<{
  organizationId: string;
}>): PermissionConditionCheckResult {
  return context.organizationId === session.organizationId
    ? {
        ok: true,
        basis: JSON.stringify(context),
      }
    : {
        ok: false,
        cause: `organizationId mismatch: ${JSON.stringify({
          context: context.organizationId,
          session: session.organizationId,
        })}`,
      };
}
