'use client';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { useLocale } from 'next-intl';
import { languageNames, Locale, locales } from './config';
import { setUserLocale } from './locale';

export default function LocaleSwitcher() {
  const locale = useLocale();

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLocale = event.target.value as Locale;
    await setUserLocale(newLocale);
  };

  return (
    <div className="header-icon-button-item">
      <GlobeAltIcon className="header-icon-button-icon" />

      <select className="border-0 focus:ring-0" value={locale} onChange={handleChange}>
        {locales.map((cur) => (
          <option key={cur} value={cur} className="rounded-lg border-0">
            {languageNames[cur]}
          </option>
        ))}
      </select>
    </div>
  );
}
