import { usePathname } from 'next/navigation';
import { normalizePath } from '../helperFunctions';
import { ISidebarProps } from './Sidebar';
import SidebarDesktopNavItem from './SidebarDesktopNavItem';
import SidebarDropdown from './SidebarDropdown';

const SidebarDesktop = (props: ISidebarProps) => {
  const pathname = usePathname();

  const sidebarItems = props.sideBarNavigationItems.map((item, index) => {
    const isActive = item.navItem.some((navItem) => {
      if (!navItem.href) return false;

      if (navItem.href.includes('?')) {
        return pathname.includes(navItem.href);
      }

      return normalizePath(navItem.href) === normalizePath(pathname);
    });

    return (
      <div key={index}>
        <div className={`sidebar-nav-item ${isActive ? 'sidebar-current-path' : ''} `}>
          <SidebarDesktopNavItem
            item={item}
            index={index}
            openSidebar={props.openSidebar}
            closeSidebar={props.closeSidebar}
            toggleSidebar={props.toggleSidebar}
            handleRowClick={props.handleRowClick}
            dropdownOpen={props.dropdownOpen}
            toggleDropdown={props.toggleDropdown}
          />
        </div>
        <SidebarDropdown
          item={item}
          dropdownOpen={props.dropdownOpen}
          openSidebar={props.openSidebar}
          index={index}
          closeSidebar={props.closeSidebar}
        />
      </div>
    );
  });
  return (
    <>
      <div
        className={`sidebar-desk-overlay transition-opacity duration-300 ${props.openSidebar ? 'z-30 opacity-50' : 'opacity-0'}`}
        onClick={() => props.toggleSidebar(false)}
      ></div>
      <div className="sidebar-desk-container">
        <div className="hidden sm:block">
          <aside className="sidebar-container">
            <nav className="sidebar-nav">{sidebarItems}</nav>
          </aside>
        </div>
      </div>
    </>
  );
};

export default SidebarDesktop;
