import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ISideBarNavigationItem } from './Sidebar';

interface ISidebarDropdownArrowsProps {
  item: ISideBarNavigationItem;
  openSidebar: boolean;
  dropdownOpen: Record<number, boolean>;
  index: number;
}

const SidebarDropdownArrows = (props: ISidebarDropdownArrowsProps) => {
  return (
    <>
      {props.item.navItem.length > 1 && props.openSidebar && (
        <div className="sidebar-dropdown-icon-container">
          <ChevronDownIcon
            className={`sidebar-dropdown-icon transform transition-transform duration-300 ease-in-out ${
              props.dropdownOpen[props.index] ? 'rotate-180' : 'rotate-0'
            }`}
          />
        </div>
      )}
    </>
  );
};

export default SidebarDropdownArrows;
