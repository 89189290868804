import { nanoid } from 'nanoid';

/**
 * Error with optional custom fields and logging at creation.
 */
export class AppError<T extends object> extends Error {
  id: string;
  timeStamp: Date;
  name: string;
  message: string;
  cause?: object | null;

  constructor(
    message: string,
    args?: {
      id?: string | null,
      name?: string;
      cause?: object | null,
      logError?: boolean;
    } & T
  ) {

    // Call the constructor of the base class (Error) with the error message
    super(message);

    // Set the prototype explicitly to ensure correct inheritance
    Object.setPrototypeOf(this, AppError.prototype);

    // Assign other properties
    Object.assign(this, args);
    this.name ||= this.constructor.name;
    this.id ||= `${this.name}#${nanoid(6)}`;
    this.timeStamp = new Date();
    this.message = message;

    // Log the error if logError is true
    if (args?.logError || args?.logError === undefined) this.logError();
  }

  // TODO: new relic integration
  private logError(): void {
    console.error(JSON.stringify(this, null, 2));
  }

  public get(fieldName: keyof T) {
    return (this as unknown as T)[fieldName];
  }
}
