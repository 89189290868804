import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { normalizePath } from '../helperFunctions';
import { ISidebarProps } from './Sidebar';
import SidebarDropdown from './SidebarDropdown';
import SidebarDropdownArrows from './SidebarDropdownArrows';
import SidebarHeaders from './SidebarHeaders';

const SidebarMobile = (props: ISidebarProps) => {
  const pathname = usePathname();

  return (
    <div
      className={`sidebar-mob-container ${props.openSidebar ? 'pointer-events-auto z-50' : 'pointer-events-none z-50'}`}
    >
      <div
        className={`sidebar-mob-wrapper transition-all duration-500 ${props.openSidebar ? 'translate-y-0 opacity-100' : '-translate-y-[calc(100% - 108px)] opacity-0'}`}
      >
        <aside className="sidebar-container">
          <nav className="sidebar-nav">
            {props.sideBarNavigationItems.map((item, index) => {
              const hasSubItems = item.navItem.length > 1;
              const isActive = item.navItem.some((navItem) => {
                if (!navItem.href) return false;

                if (navItem.href.includes('?')) {
                  return pathname.includes(navItem.href);
                }

                return normalizePath(navItem.href) === normalizePath(pathname);
              });
              // If there's only one navItem, make the whole row a link
              return (
                <div key={index}>
                  <div className={`sidebar-nav-item ${isActive ? 'sidebar-current-path' : ''}`}>
                    {!hasSubItems ? (
                      <Link
                        href={item.navItem[0]?.href || '#'}
                        className="sidebar-nav-link flex w-full items-center"
                        onClick={() => props.closeSidebar()}
                        target={item.navItem[0]?.target}
                      >
                        <div className="sidebar-nav-icon">{item.navItem[0]?.icon}</div>
                        <div className="ml-4">
                          <SidebarHeaders item={item} />
                        </div>
                      </Link>
                    ) : (
                      // If there are subitems, open dropdown
                      <div className="sidebar-nav-link" onClick={() => props.handleRowClick(index, hasSubItems)}>
                        <div className="sidebar-nav-icon">{item.navItem[0]?.icon}</div>
                        <div className="ml-4">
                          <SidebarHeaders item={item} />
                        </div>
                        <SidebarDropdownArrows
                          item={item}
                          dropdownOpen={props.dropdownOpen}
                          openSidebar={props.openSidebar}
                          index={index}
                        />
                      </div>
                    )}
                  </div>
                  <SidebarDropdown
                    item={item}
                    dropdownOpen={props.dropdownOpen}
                    openSidebar={props.openSidebar}
                    index={index}
                    closeSidebar={props.closeSidebar}
                  />
                </div>
              );
            })}
          </nav>
        </aside>
      </div>
    </div>
  );
};

export default SidebarMobile;
