import Link from 'next/link';
import { useRef, useState } from 'react';
import Tooltip from '../../general/tooltip/Tooltip';
import { useTooltipState } from '../../general/tooltip/TooltipStates';
import { ISideBarNavigationItem } from './Sidebar';
import SidebarDropdownArrows from './SidebarDropdownArrows';
import SidebarHeaders from './SidebarHeaders';

interface ISidebarNavItemProps {
  item: ISideBarNavigationItem;
  index: number;
  openSidebar: boolean;
  closeSidebar: () => void;
  // eslint-disable-next-line no-unused-vars
  toggleSidebar: (isOpen: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  handleRowClick: (index: number, hasMultipleItems: boolean) => void;
  dropdownOpen: Record<number, boolean>;
  // eslint-disable-next-line no-unused-vars
  toggleDropdown: (index: number) => void;
}

const SidebarDesktopNavItem = (props: ISidebarNavItemProps) => {
  const [tooltipState, handleTooltip] = useTooltipState();
  const [hoveredIconIndex, setHoveredIconIndex] = useState<number | null>(null);
  const iconRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleMouseEnter = () => {
    const iconRef = iconRefs.current[props.index];
    if (iconRef) {
      handleTooltip.handleTooltipMouseEnter(props.item.navItem[0]?.name, { current: iconRef }, true);
      setHoveredIconIndex(props.index);
    }
  };

  const handleMouseLeave = () => {
    handleTooltip.handleTooltipMouseLeave();
    setHoveredIconIndex(null);
  };

  const content = (
    <div className="flex items-center">
      <div className="sidebar-nav-icon">{props.item.navItem[0]?.icon}</div>
      {hoveredIconIndex === props.index && !props.openSidebar && (
        <div className="absolute left-full ml-3">
          <Tooltip text={tooltipState.tooltipText} />
        </div>
      )}
      <div
        className={`sidebar-link duration-400 overflow-hidden transition-all ${
          props.openSidebar ? 'ml-4 max-w-[200px] p-1 opacity-100' : 'ml-0 max-w-0 opacity-0'
        }`}
      >
        <SidebarHeaders item={props.item} />
      </div>
    </div>
  );

  // When sidebar is open and there's only one nav item, make the entire row a link
  if (props.openSidebar && props.item.navItem.length === 1 && props.item.navItem[0]?.href) {
    return (
      <Link
        href={props.item.navItem[0].href}
        onClick={() => props.closeSidebar()}
        target={props.item.navItem[0].target}
      >
        <div
          className="sidebar-nav-link"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={(el) => {
            iconRefs.current[props.index] = el;
          }}
        >
          {content}
        </div>
      </Link>
    );
  }

  return (
    <div
      className="sidebar-nav-link"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        // If the sidebar is closed, open it and toggle the dropdown for multi nav items
        if (!props.openSidebar) {
          props.handleRowClick(props.index, props.item.navItem.length > 1);
        } else {
          // Sidebar is already open, just toggle the dropdown if it's a multi-item nav
          if (props.item.navItem.length > 1) {
            props.toggleDropdown(props.index);
          }
        }
      }}
      ref={(el) => {
        iconRefs.current[props.index] = el;
      }}
    >
      {content}
      <SidebarDropdownArrows
        item={props.item}
        dropdownOpen={props.dropdownOpen}
        openSidebar={props.openSidebar}
        index={props.index}
      />
    </div>
  );
};

export default SidebarDesktopNavItem;
