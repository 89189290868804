import { usePathname } from 'next/navigation';
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

interface IAlert {
  id: string;
  type: 'success' | 'error' | 'warning' | 'info';
  headerTranslateKey: string;
  messageTranslateKey: string;
  isVisible: boolean;
  duration?: number;
  additionalMessage?: string;
  additionalComponent?: ReactNode;
  additionalOnCloseAction?: () => void;
  clearOnNavigate?: boolean;
}

interface IAlertState {
  createAlert: (alert: IAlert) => void;

  clearAlert: (id: string) => void;
  alerts: IAlert[];
}

const AlertContext = createContext<IAlertState | undefined>(undefined);

interface IAlertProviderProps {
  children: ReactNode;
}
export const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

export const AlertProvider = ({ children }: IAlertProviderProps) => {
  const [alerts, setAlerts] = useState<IAlert[]>([]);

  const pathname = usePathname();
  const pathnameRef = useRef(pathname);

  // Clear alerts when navigating to a new page (if clearOnNavigate is set to true)
  useEffect(() => {
    if (pathnameRef.current !== pathname) {
      alerts.forEach((alert) => {
        if (alert.clearOnNavigate) {
          clearAlert(alert.id);
        }
      });
      pathnameRef.current = pathname;
    }
  }, [pathname]);

  const createAlert = (alert: IAlert) => {
    // Only add the alert if the id doesn't already exist
    setAlerts((prevAlerts) => {
      if (!prevAlerts.some((existingAlert) => existingAlert.id === alert.id)) {
        return [...prevAlerts, alert];
      }
      return prevAlerts;
    });
  };

  const clearAlert = (id?: string) => {
    if (id) {
      // Clear a specific alert
      setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
    } else {
      // Clear all alerts
      setAlerts([]);
    }
  };

  return <AlertContext.Provider value={{ alerts, createAlert, clearAlert }}>{children}</AlertContext.Provider>;
};
