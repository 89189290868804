import { useTranslations } from 'next-intl';
import { ISideBarNavigationItem } from './Sidebar';

interface ISidebarHeadersProps {
  item: ISideBarNavigationItem;
}
const SidebarHeaders = (props: ISidebarHeadersProps) => {
  const t = useTranslations();
  return (
    <>
      {props.item.navItem.length === 1
        ? t(`navigation.navigationLinkName.${props?.item?.navItem[0]?.name}`)
        : t(`navigation.navigationLinkName.header.${props.item.header}`)}
    </>
  );
};

export default SidebarHeaders;
