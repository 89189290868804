import { createSlice } from '@reduxjs/toolkit';

const initialState: never[] = [];

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    addEvents: (state: any, action: { payload: any }) => {
      state = action.payload;
      return state;
    },
  },
});

const { addEvents } = eventsSlice.actions;

export { addEvents };
