export const allPermissions = {
  dashboard: {
    SEE: {
      description: 'Can access the dashboard',
      path: 'dashboard/SEE',
    },
  },
  priceList: {
    SEE: {
      description: 'Can see price list',
      path: 'priceList/SEE',
    },
    CHANGE: {
      description: 'Can change price list',
      path: 'priceList/CHANGE',
    },
    CREATE: {
      description: 'Can create price list',
      path: 'priceList/CREATE',
    },
  },
  notification: {
    SEE: {
      description: 'Can see notifications',
      path: 'notification/SEE',
    },
    CHANGE: {
      description: 'Can change notifications',
      path: 'notification/CHANGE',
    },
  },
  reports: {
    SEE: {
      description: 'Can see some reports',
      path: 'reports/SEE',
    },
    organization: {
      SEE: {
        description: 'Can see reports of own organization',
        path: 'reports/organization/SEE',
      },
    },
    unlimited: {
      SEE: {
        description: 'Can see reports unlimited by organizations',
        path: 'reports/unlimited/SEE',
      },
    },
  },
  organization: {
    SEE: {
      description: 'Can see their own organization',
      path: 'organization/SEE',
    },
    LIST: {
      description: 'Can browse organizations',
      path: 'organization/LIST',
    },
  },
  events: {
    CREATE: {
      description: 'Can create events',
      path: 'events/CREATE',
    },
  },
  venues: {
    LIST: {
      description: 'Can list venues',
      path: 'venues/LIST',
    },
  },
  iam: {
    user: {
      token: {
        CREATE: {
          description: 'Can create new JWT token for a user',
          path: 'iam/user/token/CREATE',
        },
      },
    },
  },
};
