import { FC } from 'react';
import { IRootLayoutProps } from '../navigation/NavigationContainer';

const MainPageContent: FC<IRootLayoutProps> = (props: IRootLayoutProps) => {
  return (
    <main className="relative h-full w-full">
      <div className="relative p-4">
        <div className="absolute left-14 top-8">{props.breadcrumb}</div>
        {props.children}
      </div>
    </main>
  );
};

export default MainPageContent;
