import '../general.css';

import type { JSX } from "react";

interface ITooltipProps {
  text: string;
}

const Tooltip = (props: ITooltipProps): JSX.Element => {
  return (
    <div className='tooltip-container'>
      <p className='tooltip-text'>{props.text}</p>
    </div>
  );
};

export default Tooltip;
