import HeaderNavigationIconButtons from './HeaderNavigationIconButtons';
import HeaderNavigationLogo from './HeaderNavigationLogo';
import HeaderNavigationSidebarButton from './HeaderNavigationSidebarButton';
// import HeaderNavigationProfileButton from './HeaderNavigationProfileButton';
import '../navigation.css';

import type { JSX } from "react";

const HeaderNavigation = (props: {
  openSidebar: boolean;
  setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  closeSidebar: () => void;
}): JSX.Element => {
  return (
    <div id="topbar" className="border-primary sticky left-0 right-0 top-0 z-50 border-t-[20px]">
      <header className="bg-background-surface w-full p-3 px-5">
        <div className="flex h-16 w-full items-center">
          <div className="flex items-center space-x-2">
            <HeaderNavigationSidebarButton
              openSidebar={props.openSidebar}
              setOpenSidebar={props.setOpenSidebar}
              closeSidebar={props.closeSidebar}
            />
            <div className="header-container-logo">
              <HeaderNavigationLogo />
            </div>
          </div>
          <div className="header-container-right">
            <HeaderNavigationIconButtons />
          </div>
        </div>
      </header>
    </div>
  );
};

export default HeaderNavigation;
