import { Dispatch, RefObject, SetStateAction, useState } from 'react';

interface ITooltipState {
  isTooltipVisible: boolean;
  setTooltipVisible: Dispatch<SetStateAction<boolean>>;
  tooltipText: string;
  setTooltipText: Dispatch<SetStateAction<string>>;
}

interface IHandleTooltipVisibility {
  // eslint-disable-next-line no-unused-vars
  handleTooltipMouseEnter: (
    // eslint-disable-next-line no-unused-vars
    tooltipText: string | null | undefined,
    // eslint-disable-next-line no-unused-vars
    ref?: RefObject<HTMLElement | null>,
    // eslint-disable-next-line no-unused-vars
    alwaysShowTooltip?: boolean,
  ) => void;
  handleTooltipMouseLeave: () => void;
}

export const useTooltipState = (): [ITooltipState, IHandleTooltipVisibility] => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState('');

  const tooltipState: ITooltipState = {
    tooltipText,
    setTooltipText,
    isTooltipVisible,
    setTooltipVisible,
  };

  const handleTooltip: IHandleTooltipVisibility = {
    handleTooltipMouseEnter: (
      tooltipText: string | null | undefined,
      ref?: RefObject<HTMLElement | null>,
      alwaysShowTooltip = false,
    ) => {
      if (ref && ref.current) {
        const element = ref.current;
        const isOverflowing = element.scrollWidth > element.clientWidth;

        if ((isOverflowing || alwaysShowTooltip) && tooltipText) {
          setTooltipVisible(true);
          setTooltipText(tooltipText);
        }
      } else {
        setTooltipVisible(true);
        if (tooltipText) setTooltipText(tooltipText);
      }
    },
    handleTooltipMouseLeave: () => {
      setTooltipVisible(false);
    },
  };

  return [tooltipState, handleTooltip];
};
