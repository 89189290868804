import { HttpError } from '@/app/helpers/HttpError';
import { doAuth, loginUrl, Session } from '@/app/utils/session';
import { getUnauthenticatedSession } from '@/app/utils/session/default';
import { useRouter } from 'next/navigation';
import useSWR from 'swr';

const url = '/api/auth/jwt/reflect/enriched';

export const useUserSessionHook = (): Session => {
  if (!doAuth) {
    return getUnauthenticatedSession();
  }
  const { data, error } = useSWR<Session, HttpError>(url, async () => {
    const response = await fetch(url);
    const responseJson = response.ok && (await response.json());
    const session = responseJson?.data?.session;
    if (!session) {
      throw new HttpError(`Failed to fetch user session data: ${response.statusText}`, {
        httpStatus: response.status,
        cause: responseJson ? { responseJson } : { response },
      });
    }
    return session;
  });
  const router = useRouter();

  if (error) {
    if (loginUrl && error.httpStatus === 401 && !loginUrl.includes('/sign-in')) {
      console.error(`Not authenticated. Redirecting to ${loginUrl}`);
      router.push(loginUrl);
    }
  }

  return data ?? getUnauthenticatedSession();
};
