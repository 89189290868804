import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { normalizePath } from '../helperFunctions';
import { ISideBarNavigationItem } from './Sidebar';

interface ISidebarDropdownProps {
  item: ISideBarNavigationItem;
  openSidebar: boolean;
  dropdownOpen: Record<number, boolean>;
  index: number;
  closeSidebar: () => void;
}

const SidebarDropdown = (props: ISidebarDropdownProps) => {
  const t = useTranslations();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const isCurrentPath = (href: string) => {
    const [basePath, queryString] = href.split('?');
    const currentUrlHasParams = searchParams.toString().length > 0;

    // If href has query params, check if they match
    if (queryString) {
      const itemQuery = new URLSearchParams(queryString);

      return (
        basePath &&
        pathname.startsWith(basePath) &&
        Array.from(itemQuery.entries()).every(([key, value]) => searchParams.get(key) === value)
      );
    } else {
      // If current URL has params but the nav item doesn't, it's not a match
      return !currentUrlHasParams && normalizePath(href) === normalizePath(pathname);
    }
  };

  return (
    <>
      {props.openSidebar && props.item.navItem.length > 1 && (
        <div
          className={`transition-all duration-700 ease-in-out ${props.dropdownOpen[props.index] ? 'sidebar-dropdown-container max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}
        >
          {props.item.navItem.map((child, childIndex) => (
            <Link href={child.href} onClick={() => props.closeSidebar()} key={childIndex} target={child.target}>
              <div className={`sidebar-dropdown-item ${isCurrentPath(child.href) ? 'sidebar-current-path' : ''}`}>
                <div className="sidebar-dropdown-text">{t(`navigation.navigationLinkName.${child.name}`)}</div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </>
  );
};

export default SidebarDropdown;
