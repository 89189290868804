import { allPermissions } from '@/app/utils/permission';
import Authorized from '@/components/iam/Authorized';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { useTranslations } from 'next-intl';

import type { JSX } from 'react';

const HeaderNavigationSidebarButton = (props: {
  openSidebar: boolean;
  setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  closeSidebar: () => void;
}): JSX.Element => {
  const t = useTranslations();

  return (
    <Authorized
      permissions={[allPermissions.dashboard.SEE]}
      else={<Bars3Icon className="header-sidebar-icon" aria-hidden="true" style={{ color: '#ccc' }} />}
    >
      <div>
        <span className="sr-only">{t('screenReaderOnly.general.openSidebar')}</span>
        {props.openSidebar ? (
          <XMarkIcon
            className="header-sidebar-icon cursor-pointer"
            aria-hidden="true"
            onClick={() => props.closeSidebar()}
          />
        ) : (
          <Bars3Icon
            className="header-sidebar-icon cursor-pointer"
            aria-hidden="true"
            onClick={() => props.setOpenSidebar(true)}
          />
        )}
      </div>
    </Authorized>
  );
};

export default HeaderNavigationSidebarButton;
