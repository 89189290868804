import { createSlice } from '@reduxjs/toolkit';

const initialState: never[] = [];

export const blocksConfigSlice = createSlice({
  name: 'blocksConfig',
  initialState,
  reducers: {
    addConfig: (state: any, action: any) => {
      state = action.payload;
      return state;
    },
  },
});

const { addConfig } = blocksConfigSlice.actions;

export { addConfig };
