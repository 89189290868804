import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Block, CustomBlock } from '@tiketti/types';
import { nanoid } from 'nanoid';

interface PageState {
  defaultBlocks: Block[];
  customBlocks: CustomBlock[];
  activeBlock: CustomBlock | null;
}

const initialState: PageState = {
  defaultBlocks: [],
  customBlocks: [],
  activeBlock: null,
};

// Define the action before the slice
export const updateCustomBlockAndDeactivate = createAction<CustomBlock>('pageConfig/updateCustomBlockAndDeactivate');

export const pageConfigSlice: any = createSlice({
  name: 'pageConfig',
  initialState,
  reducers: {
    addCustomBlock: (state: PageState, action: PayloadAction<CustomBlock>) => {
      const tempId = action.payload.tempId || nanoid(8);
      state.customBlocks = state.customBlocks.concat({ ...action.payload, tempId });
      state.activeBlock = { ...action.payload, tempId };
      return state;
    },

    updateCustomBlock: (state: any, action: PayloadAction<Partial<CustomBlock>>) => {
      const { ...newProps } = action.payload;
      const index = state.customBlocks.findIndex(
        (block: CustomBlock) =>
          (block.id && state.activeBlock?.id && block.id === state.activeBlock.id) ||
          (block.tempId && state.activeBlock?.tempId && block.tempId === state.activeBlock.tempId),
      );

      const updatedBlock = {
        ...state.activeBlock, // we need to use activeBlock here because we need to update the block that is currently being edited | active block can also be an subItem
        ...newProps,
      };

      if (index !== -1) {
        state.customBlocks[index] = updatedBlock;
      } else {
        state.customBlocks.some((block: CustomBlock, blockIndex: number) => {
          const items = block.content?.items;

          if (items) {
            return items.some((item: any, itemIndex: number) => {
              const subItems = item.subItems;
              if (subItems) {
                const subItemIndex = subItems.findIndex(
                  (subItem: any) =>
                    (subItem.id && state.activeBlock?.id && subItem.id === state.activeBlock.id) ||
                    (subItem.tempId && state.activeBlock?.tempId && subItem.tempId === state.activeBlock.tempId),
                );
                if (subItemIndex !== -1) {
                  state.customBlocks[blockIndex].content.items[itemIndex].subItems[subItemIndex] = updatedBlock;
                  return true;
                }
              }
              return false;
            });
          }
          return false;
        });
      }

      state.activeBlock = updatedBlock;
      return state;
    },

    updateCustomBlocks: (state: any, action: PayloadAction<CustomBlock[]>) => {
      state.customBlocks = action.payload;
      return state;
    },
    addDefaultBlocks: (state: any, action: any) => {
      state.defaultBlocks = action.payload;
      return state;
    },
    setActiveBlock: (state: any, action: PayloadAction<CustomBlock | null>) => {
      state.activeBlock = action.payload;
      return state;
    },
    addCustomBlocks: (state: any, action: PayloadAction<CustomBlock[] | null>) => {
      state.customBlocks = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCustomBlockAndDeactivate, (state, action) => {
      const index = state.customBlocks.findIndex(
        (block) =>
          (block.id && action.payload.id && block.id === action.payload.id) ||
          (block.tempId && action.payload.tempId && block.tempId === action.payload.tempId),
      );

      if (index !== -1) {
        state.customBlocks[index] = action.payload;
      }
      state.activeBlock = null;
    });
  },
});

export const {
  addCustomBlock,
  addDefaultBlocks,
  setActiveBlock,
  updateCustomBlock,
  updateCustomBlocks,
  addCustomBlocks,
} = pageConfigSlice.actions;
