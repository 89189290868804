import LocaleSwitcher from '@/i18n/localeSwitcher';
import { ArrowRightStartOnRectangleIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { signOut } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

import type { JSX } from 'react';

const HeaderNavigationIconButtons = (): JSX.Element => {
  const t = useTranslations();

  return (
    <div className="header-icon-button-container">
      <div className="header-icon-button-position">
        <Link href={`${process.env.NEXT_PUBLIC_PIPETTI_URL}/pro`} className="header-icon-button-item">
          <ChevronLeftIcon className="header-icon-button-back" />
          <p>{t('navigation.headerNavigation.navigateBack')}</p>
        </Link>

        <div className="border-ui-grey-90 mx-2 border-r-2">&nbsp;</div>

        <LocaleSwitcher />
        <div className="border-ui-grey-90 mx-2 border-r-2">&nbsp;</div>

        <button
          className="header-icon-button-item"
          onClick={async () => {
            await signOut();
          }}
        >
          <p>{t('navigation.headerNavigation.logout')}</p>
          <ArrowRightStartOnRectangleIcon className="header-icon-button-icon ml-2 cursor-pointer" />
        </button>
        <div className="border-ui-grey-90 mx-2 border-r-2">&nbsp;</div>
      </div>
    </div>
  );
};

export default HeaderNavigationIconButtons;
