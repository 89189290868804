const CHECK_PERMISSIONS = process.env.NEXT_PUBLIC_CHECK_PERMISSIONS;
const PIPETTI_URL = process.env.NEXT_PUBLIC_PIPETTI_URL;
const DASHBOARD_URL = process.env.NEXT_PUBLIC_DASHBOARD_URL;

if (!CHECK_PERMISSIONS) {
  throw new Error('Missing NEXT_PUBLIC_CHECK_PERMISSIONS');
}

if (!PIPETTI_URL) {
  throw new Error('Missing NEXT_PUBLIC_PIPETTI_URL');
}

if (!DASHBOARD_URL) {
  throw new Error('Missing NEXT_PUBLIC_DASHBOARD_URL');
}

export const doAuth = !!JSON.parse(CHECK_PERMISSIONS);
export const loginUrl = `${DASHBOARD_URL}/sign-in?redirect=${encodeURIComponent(DASHBOARD_URL)}`;
