import { Permission } from '@/app/utils/permission';
import { doAuth } from './configuration';
import { Permissible, PermissibleList, SessionOrError } from './types';

const logPermissionChecks = process.env.DEBUG?.includes('permissionChecks');

/**
 * Returns whether given session has requested permission.
 * @param session SessionOrError (from authentication&authorization utility; that is, JWT cookie from Authentication API)
 * @param permission Permission to check for (from `allPermissions` in `utils/permissions`)
 */
export const isAuthorized = (session: SessionOrError, permission: Permission): boolean => {
  const result = ('rights' in session && session.rights?.includes(permission.path)) || !doAuth;
  if (logPermissionChecks) {
    const { path } = permission;
    const rights = 'rights' in session ? session.rights : session;
    console.log('permissionCheck', JSON.stringify({ result, path, rights }));
  }
  return result;
};

/**
 * Returns a list of all values associated with a permission that the user has, from a list of
 *
 * { permission: Permission, values: T[] }
 *
 * @param session SessionOrError (from authentication&authorization utility; that is, JWT cookie from Authentication API)
 * @param permissibleList AuthorizationMap<T> where T is type of the items to be listed.
 */
export const chooseAllAuthorized = <T>(session: SessionOrError, permissibleList: PermissibleList<T>): T[] => {
  return permissibleList.filter(filterForAuthorized(session)).flatMap((l) => l.values);
};

/**
 * Returns first one of all values associated with a permission that the user has, from a list of
 *
 * { permission: Permission, values: T[] }
 *
 * @param session SessionOrError (from authentication&authorization utility; that is, JWT cookie from Authentication API)
 * @param permissibleList AuthorizationMap<T> where T is type of the item to be returned. */
export const chooseFirstAuthorized = <T>(
  session: SessionOrError,
  permissibleList: PermissibleList<T>,
): T | undefined => {
  const checkPermission = filterForAuthorized(session);

  return permissibleList.find(({ permission, values }) => values.length && checkPermission({ permission }))?.values[0];
};

const filterForAuthorized =
  (session: SessionOrError) =>
  ({ permission }: Permissible) =>
    Array.isArray(permission) ? permission.some((p) => isAuthorized(session, p)) : isAuthorized(session, permission);
